<template>
  <v-container class="pt-0">
    <v-row no-gutters>
      <v-col>
        <div
          v-if="quiz.conclude_title"
          class="congrats"
        >
          {{ quiz.conclude_title }}
        </div>
        <img
          class="conclude_img"
          src="@/assets/quiz/conclude.jpg"
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col
        lg="10"
        xs="6"
        offset-lg="1"
      >
        <h3
          v-if="error"
          class="text-center mt-16"
        >
          {{ error }}
        </h3>
        <div
          v-else
          class="congratulation-text mt-16"
          v-html="quiz.conclude"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="footer_logo">
        <img
          src="@/assets/quiz/logo.png"
          class="mb-6"
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col class="footer_logo">
        <div
          v-if="quiz.end_footer"
          v-html="quiz.end_footer"
        />
        <p>
          Coverage is subject to policy terms, conditions, limitations, exclusions, underwriting review and approval, and may vary or not be available for all risks or in all states. Rates, discounts vary,
          are determined by many factors, are subject to change. Policies are written by one of the licensed insurers of American Modern Insurance Group, Inc., including but not limited to American
          Modern Property and Casualty Insurance Company (CA Lic. No. 6129-1.)
        </p>
        <p>
          American Modern Insurance Group, American Modern, AMsuite, modernLINK, and Homeowners FLEX are trademarks or registered trademarks of American Modern Insurance Group, Inc.
        </p>
        <p>
          © {{ new Date().getFullYear() }}. American Modern Insurance Group, Inc., 7000 Midland Blvd. Cincinnati, OH 45102-2607, USA. All rights reserved.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Reward',
  data () {
    return {
      reward: null,
      correct: 0,
      error: false,
      answers: [],
      quiz_id: null,
      quiz: {}
    }
  },
  beforeMount () {
    this.quiz_id = parseInt(this.$route.params.quiz_id)
    const email = localStorage.getItem('email')
    if (email === null) {
      this.$store.commit('setNotification', { color: 'error', message: 'Sorry this page cannot be accessible' })
      console.log('Please define email for reward and go to main page')
    } else {
      this.answers = JSON.parse(localStorage.getItem('answers' + email + this.quiz_id)) || []
      this.correct = this.calculateCorrectAnswers(this.answers, 0)
      this.getQuizInfo()
    }
  },
  methods: {
    /* todo would be organize it better */
    percentage (correct, totalQuestions) {
      return ((100 * correct) / totalQuestions).toFixed(0)
    },
    closest (arr, closestTo) {
      return arr.reduce((a, b) => {
        return Math.abs(b - closestTo) < Math.abs(a - closestTo) ? b : a
      })
    },
    pluck (array, key) {
      return array.map(item => item[key])
    },
    async getQuizInfo () {
      await this.$http
        .get(`/quizes/active/?id[]=${this.$route.params.quiz_id}&take=500&status=true`)
        .catch(error => this.displayError(error))
        .then(({ data: { data } }) => {
          if (data.length === 0) {
            this.error = 'Sorry this quiz not found or disabled'
          }
          this.quiz = data[0]
          this.quiz.params = data[0].params

          if (this.answers.length !== this.quiz.questions.length) {
            const message = 'You didn\'t answer to all questions please come back and try again'
            this.error = message
            this.$store.commit('setNotification', { color: 'error', message: message })
          }

          const scoresByQuiz = this.pluck(this.quiz.params, 'score')
          const correctPercentage = this.percentage(this.correct, this.answers.length)
          const closestRewardInPer = this.closest(scoresByQuiz, correctPercentage)
          const rewardObj = this.quiz.params.find(item => item.score === closestRewardInPer)
          this.reward = rewardObj.reward
          this.quiz.conclude = this.prepareConcText(this.quiz.conclude, this.correct, this.reward)
          this.quiz.conclude_title = this.prepareConcText(this.quiz.conclude_title, this.correct, this.reward)
          // // save results in system
          this.storeToServer(localStorage.getItem('email'), this.correct, this.quiz.questions.length, this.quiz_id)
          // save result in marketo
          this.storeToMarketo(localStorage.getItem('email'), this.reward)

          this.storeToTangoCard(localStorage.getItem('email'), this.reward, this.quiz_id)
        })
    },
    storeToMarketo (email, reward) {
      this.$http
        .post('marketo/leads', { email, reward })
        .catch(error => this.displayError(error))
    },
    storeToTangoCard (email, reward, quizID) {
      this.$http
        .post('tango_card/order', { email, reward, quiz_id: quizID })
        .catch(error => this.displayError(error))
    },
    storeToServer (email, amountCorrect, totalQuestions, quizID) {
      this.$http
        .post('/results', { email, correct_answers: amountCorrect, total_questions: totalQuestions, quiz_id: quizID })
        .catch(error => this.displayError(error))
    },
    calculateCorrectAnswers (answers, amountCorrect) {
      return answers.reduce((acc, item) => {
        return item.answer === true ? acc + 1 : acc
      }, amountCorrect)
    },
    prepareConcText (text, amountCorrect, reward) {
      const cText = text.replaceAll('%correct%', amountCorrect)
      return cText.replaceAll('%reward%', reward)
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.footer_logo p {
  font-size: 12px;
  color: #606161;
}
.congrats {
  position: absolute;
  font-size: 70px;
  color: #fff;
  margin: 20px 0 0 20px;
}

.congratulation-text {
  text-align: center;
  font-size: 38px;
  line-height: 48px;
  color: #616161;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .congrats {
    font-size: 30px;
  }
  .congratulation-text {
    font-size: 24px;
    line-height: 32px;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .intro_text_header {
    font-size: 40px;
    line-height: 40px;
    margin: 10px 0 0 30px;
  }
  .text-intro {
    font-size: 20px;
  }
}
</style>
